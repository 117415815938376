.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: none;
}
.wrapper[data-active="true"] {
  display: block;
}
.merchCover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  width: 150px;
  background-color: black;
  color: white;
  outline: none;
  border: none;
  font-size: 1.2em;

  svg {
    width: 20px;
    height: 20px;
    fill: white;
    margin-right: 10px;
  }
}

.vinylControls {
  position: absolute;
  bottom: 210px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100px;
  font-size: 0.8em;
  padding: 8px 8px 4px 8px;
}

.shirtBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  cursor: pointer;
  
  button {
    margin-bottom: 10px;
    pointer-events: none;
  }
}

.shirtBtn[data-direction='left'] {
  left: 10px;
}
.shirtBtn[data-direction='right'] {
  right: 10px;
}

@media (min-width: 700px) {
  .shirtBtn[data-direction='left'] {
    left: 300px;
  }
  .shirtBtn[data-direction='right'] {
    right: 300px;
  }
}