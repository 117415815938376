.wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.option {
  position: relative;
  min-height: 100px;
  width: 33%;
  box-sizing: border-box;

  input {
    display: none;
  }

  label {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 2em;
      height: 2em;
      fill: white;
      margin-bottom: 10px;
    }
  }
  label::after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  

  input:checked+label {
    border: 1px solid var(--color-grey);
  }
  input:checked+label::before {
    content: attr(data-text);
    position: absolute;
    bottom: -1.5em;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 0.7em;
    text-align: center;
  }
}

.wrap[data-shape="square"] {
  .option {
    label {
      padding: 40px;
    }


    label, label::after {
      border-radius: 4px;
    }

    label::after  {
      border: 2px solid white;
    }
  }
}

@media (max-width: 500px) {
  .wrap[data-shape="square"] {
    .option {
      label {
        padding: 30px;
      }
    }
  }
}


.option[data-color='default'] {
  label::after  {
    background-color: grey;
  }
}
.option[data-color='black'] {
  label::after  {
    background-color: black;
  }
}
.option[data-color='white'] {
  label::after  {
    border: 1px solid grey;
  }
}

// PLACEHOLDER FOR OUTLINE STYLES