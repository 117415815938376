.sceneWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: black;
}

.loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.webglWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.webglWrapper[data-role="3d"][data-pointer="true"] {
  pointer-events: all;
}
.webglWrapper[data-role="3d"][data-cursorpointer="true"] {
  cursor: pointer;
}

.webglWrapper[data-role="3d"][data-pointer="false"] {
  pointer-events: none;
}
.webglWrapper[data-role="3d"][data-cursorpointer="false"] {
  cursor: auto;
}

.cameraPan {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}
.cameraPan[data-direction="left"] {
  left: 10px;
}
.cameraPan[data-direction="right"] {
  right: 10px;
}

@media (max-width: 600px) {
  .cameraPan {
    top: auto;
    transform: auto;
    bottom: 100px;
    width: 65px;
    height: 65px;
  }
}

.videoMaterial {
  position: absolute;
  top: -999vh;
  left: -999vw;
  visibility: hidden;
}
