@font-face {
  font-family: 'marker';
  src: url('https://d1ebxi7lthqj9n.cloudfront.net/fonts/PermanentMarker-Regular.ttf');
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55,63,83,0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.whiteboard {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-top: 30px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 800px;
}

.whiteboard img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.close {
  position: absolute;
  width: 6%;
  height: 10%;
  top: 8%;
  right: 2%;
  cursor: pointer;
}

.startButton {
  position: absolute;
  width: 40%;
  height: 20%;
  bottom: 9%;
  right: 2%;
  cursor: pointer;
}

@media (max-width: 800px) {
  .whiteboard {
    max-width: 95%;
  }
}

.winner {
  position: absolute;
  width: 22%;
  height: 4%;
  color: black;
  font-family: 'marker';
  text-align: center;
  font-size: 0.7em;
  margin-left: -28px;
  margin-top: 6px;
}
@media (max-width: 800px) {
  .winner {
    font-size: 10px;
    margin-top: 4px;
    margin-left: -15px;
    transform: scale(0.67);
  }
}

.winner[data-index='0'] {
  top: 25%;
  left: 24.5%;
}
@media (max-width: 800px) {
  .winner[data-index='0'] {
    top: 27%;
  }
}
.winner[data-index='1'] {
  top: 30%;
  left: 24.5%;
}
@media (max-width: 800px) {
  .winner[data-index='1'] {
    top: 31%;
  }
}


.winner[data-index='2'] {
  top: 40%;
  left: 42%;
}
.winner[data-index='3'] {
  top: 45%;
  left: 42%;
}
.winner[data-index='4'] {
  top: 47%;
  left: 61%;
}


.winner[data-index='5'] {
  top: 70%;
  left: 24.5%;
}
@media (max-width: 800px) {
  .winner[data-index='5'] {
    top: 69%;
  }
}
.winner[data-index='6'] {
  top: 74.5%;
  left: 24.5%;
}
@media (max-width: 800px) {
  .winner[data-index='6'] {
    top: 73.5%;
  }
}
.winner[data-index='7'] {
  top: 55%;
  left: 42%;
}

.winner[data-index='8'] {
  top: 60%;
  left: 42%;
}
.winner[data-index='9'] {
  top: 52%;
  left: 61%;
}

.winner[data-index='10'] {
  top: 50%;
  left: 80%;
}