
.boxSetWrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 30px;
  flex-wrap: wrap;

  img {
    width: 50%;
  }
}
.boxSetInfo {
  width: 50%;
  height: 320px;
  overflow-y: scroll;
  text-align: left;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 0.8em;

  h3 {
    margin-bottom: 10px;
  }

  ul {
    margin: 0 0 15px 10px;
    padding: 0
  }

  p {
    font-size: 1em;
    font-weight: 800;
  }
}


.boxSetWrap[data-size='sm'] {
  .boxSetInfo {
    width: 50%;
    height: auto;
    overflow: auto;
  }
}

.boxSetScrollTip {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: center;
  height: 30px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  pointer-events: none;
}

@media (max-width: 700px) {
  .boxSetWrap {
    margin-top: -20px;
    margin-bottom: 10px;
    height: 350px;
    overflow-y: scroll;

    img {
      width: 70%;
    }
  }
  .boxSetInfo {
    margin-top: 20px;
    width: 100%;
    height: auto;
    padding-bottom: 20px;
  }

  .boxSetScrollTip {
    width: 100%;
  }
}

@media (max-height: 630px) {
  .boxSetWrap {
    height: 260px;
    overflow-y: scroll;
  }
}