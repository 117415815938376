.paint {
  position: fixed;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: black;
  background-image: url('https://d1ebxi7lthqj9n.cloudfront.net/images/concrete-bg_1.jpg');
  background-size: cover;
}
@media (max-width: 905px) {
  .paint {
    position: fixed;
    top: 50px;
  }
}

.canvasWrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: black;

  canvas {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    box-sizing: border-box;
  }
}

.tape {
  position: absolute;
  pointer-events: none;
}

.tape[data-position='top'] {
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 115%;
  height: 20px;
}
.tape[data-position='bottom'] {
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 115%;
  height: 20px;
}
.tape[data-position='left'] {
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 115%;
}
.tape[data-position='right'] {
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 115%;
}
.canvasWrap[data-layout="full"] .tape {
  display: none;
}

.customCursor {
  pointer-events: none;
  user-select: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 140px;
  height: 140px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.customCursor[data-mode="draw"] {
  transform: rotate(-20deg);
  background-image: url('https://d1ebxi7lthqj9n.cloudfront.net/images/spray-cursor_1.png');
}

.customCursor[data-mode="stencil"][data-stencil="1"] {
  background-image: url('https://d1ebxi7lthqj9n.cloudfront.net/images/stencil-curor-1.png');
}
.customCursor[data-mode="stencil"][data-stencil="2"] {
  background-image: url('https://d1ebxi7lthqj9n.cloudfront.net/images/stencil-curor-2.png');
}
.customCursor[data-mode="stencil"][data-stencil="3"] {
  background-image: url('https://d1ebxi7lthqj9n.cloudfront.net/images/stencil-curor-3.png');
}

.close {
  position: fixed;
  top: 30px;
  right: 30px;
  cursor: pointer;
}