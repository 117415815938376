@font-face {
  font-family: 'univers-lt-std-light';
  src: url('https://d1ebxi7lthqj9n.cloudfront.net/fonts/univers-ltdstd-light_v5.otf');
}
@font-face {
  font-family: 'univers-lt-std-light-con';
  src: url('https://d1ebxi7lthqj9n.cloudfront.net/fonts/univers-ltdstd-light-cn_v4.otf');
}

.heading {
  margin-top: 30px;
  font-family: 'univers-lt-std-light-con';
  font-size: 1.3em;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  font-family: 'univers-lt-std-light-con';
  margin: 15px auto 20px auto;
}

.digit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timerNumber {
  font-size: 4em;
}

.spacer {
  font-size: 4em;
  margin-bottom: 35px;
}

.timerLabel {
  font-size: 1.2em;
}

.btnGroup {
  margin-top: 20px;
}