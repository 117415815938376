.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,1);
}

.content {
  width: 500px;
}
@media (max-width: 500px) {
  .content {
    width: 86vw
  }
}

.iframeWrap {
  position: relative;
  padding-bottom: 56.25%;
  background-color: black;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.close {
  cursor: pointer;
}

.loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}