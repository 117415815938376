@font-face {
  font-family: 'univers-lt-std-bold-con';
  src: url('https://d1ebxi7lthqj9n.cloudfront.net/fonts/univers-bold-con_v4.otf');
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
}

.close {
  margin-top: 55px;
  cursor: pointer;
}

.control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    fill: white;
    pointer-events: none;
  }
}
.control[data-direction='prev'] {
  left: 0;
}
.control[data-direction='next'] {
  right: 0;
}

.imageWrap {
  position: relative;
  height: 40vh;
  max-height: 400px;
  width: 600px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: none;
    opacity: 0;
  }

  img[data-active="true"] {
    opacity: 1;
  }
}
@media (max-width: 665px) {
  .imageWrap {
    width: 80vw;
    height: 300px;
    max-height: 40vh;
  }
}

.zoomLink {
  position: absolute;
  bottom: -34px;
  font-size: 0.8em;
  text-decoration: underline;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: white;
    width: 1em;
    height: 1em;
    margin-left: 4px;
  }
}

.counter {
  padding: 10px 20px 6px 20px;
  margin-top: 45px;
  background-color: white;
  color: black;
  box-sizing: border-box;
  font-family: 'univers-lt-std-bold-con';
}