.wrapper {
  position: fixed;
  top: 0;
  right: -150%;
  padding: 0 40px;
  box-sizing: border-box;
  height: 100%;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: right 500ms ease;

  ul {
    list-style: none;
    text-align: center;
    padding-left: 0;

    li {
      margin: 20px auto;
    }
  }
}
.wrapper[data-menuactive="true"] {
  right: 0;
  transition: right 500ms ease;
}
@media (max-width: 600px) {
  .wrapper {
    padding: 0 10px;
  }
}

.logo {
  height: 60px;
}

.emailForm {
  margin: 0 auto 35px auto;
}

.icons {
  font-size: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
  a {
    margin: 0 8px;
  }

  svg {
    fill: white;
    width: 1.1em;
    height: 1.1em;
  }
}

.iconRow {
  margin: 5px auto;
}