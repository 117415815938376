@font-face {
  font-family: 'univers-lt-std-bold-con';
  src: url('https://d1ebxi7lthqj9n.cloudfront.net/fonts/univers-bold-con_v4.otf');
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
}

.instructions {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55,63,83,0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.instructionIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;

  svg {
    fill: white;
    width: 100px;
    height: 100px;
    margin: 15px auto;
  }
}



.scrollWrap {
  position: absolute;
  height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  cursor: grab;
}
@media (max-width: 905px) {
  .scrollWrap {
    padding-top: 80px;
  }
}

.wall {
  position: relative;
  height: 100%;
  pointer-events: none;
}


.tooltip {
  position: absolute;
  display: block;
  user-select: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
}


:root {
  --marker-diameter: 10px;
}
.tooltipMarker {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(1) ;
  left: 0;
  right: 0;
  margin: auto;
  width: var(--marker-diameter);
  height: var(--marker-diameter);
  border-radius: 50%;
  background-color: white;
  transform-origin: center center;
  transform-box: fill-box;
  animation: pulse 4s ease infinite;

  pointer-events: none;
}

@keyframes pulse {
  0% {
    transform: translateY(-50%) scale(1);
  }
  5% {
    transform: translateY(-50%) scale(0.5);
  }
  8% {
    transform: translateY(-50%) scale(1.5);
  }
  18% {
    transform: translateY(-50%) scale(1);
  }
  100% {
    transform: translateY(-50%) scale(1);
  }
}

.tooltipMarkerHalo {
  content: '';
  position: absolute;
  top: 50%;
  transform: scale(1) translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: calc(var(--marker-diameter) * 1.5);
  height: calc(var(--marker-diameter) * 1.5);
  border-radius: 50%;
  background-color: white;
  opacity: 0;
  transform-origin: center;
  animation: pulseHalo 4s ease infinite;

  pointer-events: none;
}
.tooltip[data-color="purple"] .tooltipMarkerHalo {
  background-color: #A393F5;
}
@keyframes pulseHalo {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  8% {
    opacity: 0.5;
    transform: translateY(-50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-50%) scale(3.5);
  }
}

.tooltipLabel {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 13px;
  opacity: 0;
  transition: opacity 300ms ease;
  color: black;

  pointer-events: none;
}
.tooltipLabel:before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: -4px;
  left: calc(50% - 4px);
  transform: rotate(45deg);
  background: #fff;
  border-radius: 2px 0 0;
  box-shadow: 0 0 10px #0000001a;
}

.tooltipLabelInner {
  display: block;
  padding: 4px 10px 5px;
  position: relative;
  background: #fff;
  border-radius: inherit;
  font-size: 14px;
  line-height: 1.25;
  white-space: nowrap;
}


.tooltip:hover {
  .tooltipLabel {
    opacity: 1;
    transition: opacity 300ms ease;
  }
}


.wallItem {
  position: absolute;
  cursor: pointer;
  height: 100px;
}

.wallItem[data-item='item-1'] {
  top: 18%;
  left: 0.4%;
  width: 3%;
  height: 10%;
}
.wallItem[data-item='item-2'] {
  top: 57%;
  left: 1%;
  width: 4%;
  height: 15%;
}
.wallItem[data-item='item-3'] {
  top: 79%;
  left: 1.4%;
  width: 2%;
  height: 15%;

  .tooltipMarker, .tooltipMarkerHalo {
    top: 10%;
    left: 60%;
  }
}
.wallItem[data-item='item-4'] {
  top: 48%;
  left: 7.5%;
  width: 2%;
  height: 15%;
}
.wallItem[data-item='item-5'] {
  top: 70%;
  left: 11.2%;
  width: 2%;
  height: 15%
}
.wallItem[data-item='item-6'] {
  top: 12%;
  left: 9.5%;
  width: 2%;
  height: 15%;
}
.wallItem[data-item='item-7'] {
  top: 35%;
  left: 18%;
  width: 2%;
  height: 15%;
}
.wallItem[data-item='item-8'] {
  top: 70%;
  left: 26.5%;
  width: 2%;
  height: 15%;
}
.wallItem[data-item='item-9'] {
  top: 53%;
  left: 30.2%;
  width: 2%;
  height: 15%;
}
.wallItem[data-item='item-10'] {
  top: 15%;
  left: 35.8%;
  width: 2%;
  height: 15%;
}
.wallItem[data-item='item-11'] {
  top: 58%;
  left: 41.4%;
  width: 2%;
  height: 8%;
}
.wallItem[data-item='item-12'] {
  top: 38%;
  left: 42.6%;
  width: 2%;
  height: 15%;

  .tooltipMarker, .tooltipMarkerHalo {
    top: 78%;
    right: 35%;
  }
}
.wallItem[data-item='item-13'] {
  top: 64.5%;
  left: 53.5%;
  width: 2%;
  height: 10%;
}
@media (max-width: 905px) {
  .wallItem[data-item='item-13'] {
    top: 66%;
  }
}
.wallItem[data-item='item-14'] {
  top: 71%;
  left: 58.6%;
  width: 2%;
  height: 8%;
}
.wallItem[data-item='item-15'] {
  top: 80%;
  left: 62.5%;
  width: 2%;
  height: 8%;
}
.wallItem[data-item='item-16'] {
  top: 77%;
  left: 66.38%;
  width: 2%;
  height: 8%;
}
.wallItem[data-item='item-17'] {
  top: 55%;
  left: 64.8%;
  width: 3%;
  height: 16%;

  .tooltipMarker, .tooltipMarkerHalo {
    left: 10%;
  }
}
.wallItem[data-item='item-18'] {
  top: 10%;
  left: 68%;
  width: 3%;
  height: 16%;
}
.wallItem[data-item='item-19'] {
  top: 50%;
  left: 71.2%;
  width: 2%;
  height: 15%;

  .tooltipMarker, .tooltipMarkerHalo {
    top: 90%;
  }
}
.wallItem[data-item='item-20'] {
  top: 14%;
  left: 80.5%;
  width: 2%;
  height: 8%;
}
.wallItem[data-item='item-21'] {
  top: 14%;
  left: 87%;
  width: 2%;
  height: 8%;
}
.wallItem[data-item='item-22'] {
  top: 50.5%;
  left: 86.2%;
  width: 4%;
  height: 20%;
}
.wallItem[data-item='item-23'] {
  top: 39.8%;
  left: 91.55%;
  width: 2%;
  height: 7%;
}
.wallItem[data-item='item-24'] {
  top: 35%;
  left: 93.6%;
  width: 3%;
  height: 50%;

  .tooltipMarker, .tooltipMarkerHalo {
    top: 58%;
    left: 50%;
  }
}
.wallItem[data-item='item-25'] {
  top: 64%;
  left: 97.55%;
  width: 1.5%;
  height: 12%;

  .tooltipMarker, .tooltipMarkerHalo {
    left: 80%;
  }
}




.assetDownload {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EBEBEB;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  color: black;
  text-decoration: underline;
  font-family: 'univers-lt-std-bold-con';
  cursor: pointer;

  svg {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
}