@font-face {
  font-family: 'univers-lt-std-bold-con';
  src: url('https://d1ebxi7lthqj9n.cloudfront.net/fonts/univers-bold-con_v4.otf');
}


.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55,63,83,0.8);
}

.topContent {
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.close {
  left: 0;
  right: 0;
  margin: auto;
  width: 110px;
  background-color: black;
  color: white;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 8px 8px 8px;

  svg {
    width: 18px;
    height: 18px;
    fill: white;
    margin-right: 10px;
    margin-bottom: 4px;
  }
}

.cta {
  position: absolute;
  bottom: 120px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  color: black;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 10px 8px 10px;
  box-sizing: border-box;

  svg {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
  }
}