.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: black;
  background-image: url('https://d1ebxi7lthqj9n.cloudfront.net/images/warehouse-exterior.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media (max-width: 800px) {
  .wrapper {
    // background-image: url('');
  } 
}

.wrapperOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}

.content {
  position: relative;
  max-width: 500px;
}
@media (max-width: 800px) {
  .content {
    max-width: 95%;
  }
}


.enterWithEmail {
  margin: 20px auto;
}
@media (max-height: 560px) {
  .enterWithEmail {
    margin: 5px auto;
  }
}

.welcomeLogo {
  max-width: 500px;
  margin-bottom: 20px;
}
@media (max-height: 673px) {
  .welcomeLogo {
    max-width: 90%;
    margin-bottom: 10px;
  }
}
@media (max-width: 600px) {
  .welcomeLogo {
    max-width: 100%;
  }
}

.welcomeTitle {
  color: white;
  font-size: 3.5em;
  font-weight: 400;
  margin: 20px auto;
}
@media (max-width: 800px) {
  .welcomeTitle {
    margin: 10px auto;
    font-size: 1.9em;
  }
}

.enterContent {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: -999;
}

.enterContent[data-ready="true"] {
  position: relative;
  z-index: 0;
  visibility: visible;
  opacity: 1;
}

@media (max-height: 560px) {
  .enterContent {
    button {
      margin-top: 0;
      font-size: 0.8em;
    }
  }
}

.emailClose {
  cursor: pointer;
}



.mainWelcome[data-active='false'] {
  display: none;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}