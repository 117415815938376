@font-face {
  font-family: 'univers-lt-std-con';
  src: url('https://d1ebxi7lthqj9n.cloudfront.net/fonts/univers-ltstd-cn_v3.otf');
}

.footer {
  position: fixed;
  bottom: 6px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.7em;
  margin: auto;
  left: 0;
  right: 0;
  font-family: 'univers-lt-std-con', sans-serif;

  div, a, span {
    margin: 0px 3px;
  }
}