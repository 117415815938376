.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spacer {
  width: 60px;
}

.logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  max-width: 450px;
}
@media (max-width: 600px) {
  .logo {
    left: 20px;
    right: auto;
    max-width: 300px;
  }
}

.backBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
@media (max-width: 905px) {
  .backBtn {
    top: 55px;
    left: 0;
    transform: auto;
    background-color: black;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
  }
}

.menuToggle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 30px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.iconCenter {
  width: 100%;
  height: 2px;
  opacity: 1;
  background-color: white;
  transition: all 300ms ease;
}

.iconAnimate::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform-origin: top left;
  background-color: white;
  transition: all 300ms ease;
}
.iconAnimate::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform-origin: bottom left;
  background-color: white;
  transition: all 300ms ease;
}

.wrapper[data-menuactive="true"] .iconCenter {
  opacity: 0;
  transition: all 300ms ease;
}
.wrapper[data-menuactive="true"] .iconAnimate::before {
  top: -3px;
  transform: rotate(45deg) ;
  background-color: white;
  transition: transform 300ms ease, top 300ms ease;
}
.wrapper[data-menuactive="true"] .iconAnimate::after {
  bottom: -3px;
  transform: rotate(-45deg);
  background-color: white;
  transition: transform 300ms ease, bottom 300ms ease;
}