.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal[data-active="false"] {
  display: none;
}
.modal[data-background="blue"] {
  background-color: rgba(55,63,83,0.8);
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5em;
  color: white;
  cursor: pointer;
}
@media (max-width: 600px) {
  .close {
    top: 12px;
    right: 12px;
  }
}

.box {
  position: relative;
  max-width: 700px;
  text-align: center;
  padding: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
  box-shadow: 0px 0px 20px #87A2BE;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .box {
    max-width: 95%;
  }
}

.content {
  padding: 60px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    margin-top: 0;
  }
}
.modal[data-position="bottom"] .box {
  position: absolute;
  bottom: 60px;
}
@media (max-height: 673px) {
  .content {
    padding: 40px;
  }
}
@media (max-width: 600px) {
  .content {
    padding: 20px;
  }
}


.box[data-role="merch"] {
  position: absolute;
  bottom: 60px;
  max-width: 300px;
  left: 0;
  right: 0;
  margin: auto;

  h3 {
    font-size: 1.3em;
    margin: 5px auto 10px auto;
  }

  button {
    font-size: 0.8em;
  }
}
.box[data-role="merch"] .content {
  padding: 14px;

  h3 {
    margin-top: 0;
  }
}

@media (max-width: 700px) {
  .modal[data-role='welcome'] {
    h2 {
      font-size: 1.25em;
    }
    p {
      font-size: 1em;
    }
  }
}

.modal[data-role='gallery'] .content{
  padding: 20px;
}
.modal[data-role='video'] .content{
  padding: 30px;
}

@media (max-width: 600px) {
  .content {
    padding-top: 30px !important;
  }
  .box[data-role="merch"] .content{
    padding-top: 20px !important;
    padding: 20px !important;

    h3 {
      margin-top: 0;
    }
  }
}

.modal[data-role='screening-room'] .box{
  width: 70%;
  max-width: 1200px;
  max-height: 84vh;
}
@media (max-width: 1000px) {
  .modal[data-role='screening-room'] .box{
    width: 90%;
    max-width: 90%;
  }
}

.modal[data-role='sharing'] .box{
  width: 450px;
}
.modal[data-role='sharing'] .content{
  padding: 30px;
}

