@font-face {
  font-family: 'univers-lt-std-con';
  src: url('https://d1ebxi7lthqj9n.cloudfront.net/fonts/univers-ltstd-cn_v3.otf');
}
@font-face {
  font-family: 'univers-lt-std-light';
  src: url('https://d1ebxi7lthqj9n.cloudfront.net/fonts/univers-ltdstd-light_v5.otf');
}
@font-face {
  font-family: 'univers-lt-std-light-con';
  src: url('https://d1ebxi7lthqj9n.cloudfront.net/fonts/univers-ltdstd-light-cn_v4.otf');
}
@font-face {
  font-family: 'univers-lt-std-bold-con';
  src: url('https://d1ebxi7lthqj9n.cloudfront.net/fonts/univers-bold-con_v4.otf');
}

body {
  margin: 0;
  --color-background: #171C1F;
  --color-primary: #1B71FF;
  --color-mute: #F1F1F1;
  --color-text: #FFFFFF;
  --color-grey-md: #8D8D8D;
  --color-grey-lt: #B6B6B6;
  --box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.1);
  --screen-sm: 800px;
  color: var(--color-text);
  width: 100%;
  height: 100%;
  font-family: 'univers-lt-std-light', sans-serif;
  font-weight: 100;
  user-select: none;
}

.password-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.3em;
  background-color: black;

  form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    // height: 300px;
    width: 300px;
  }

  button, input {
    width: 100%;
    box-sizing: border-box;
  }

  button {
    background-color: grey;
    color: white;
    border: none;
    outline: none;
  }
  p {
    font-size: 0.6em;
  }
}

.grecaptcha-badge {
	display: none !important;
}

.content-block {
  margin: 20px auto;

  h2 {
    margin: 0;
  }
}
.content-block:last-child {
  margin-bottom: 0;
}

@media (max-height: 600px) {
  .content-block {
    margin: 10px auto;
  }
}
@media (max-width: 600px) {
  .content-block {
    margin: 15px auto;
  }
}

.block--400 {
  max-width: 400px;
}
.block--300 {
  width: 300px;
}


// TEXT
a {
  color: inherit;
  text-decoration: none;
}
p {
  line-height: 1.2em;
  font-size: 18px;
}
h2 {
  font-family: 'univers-lt-std-bold-con', sans-serif;
  text-transform: uppercase;
  font-size: 1.8em;
}
h3 {
  font-family: 'univers-lt-std-bold-con', sans-serif;
  text-transform: uppercase;
  font-size: 1.5em;
}

.text--warning {
  font-family: 'univers-lt-std-light-con', sans-serif;
  font-size: 1.4em;
  text-transform: uppercase;
}

hr {
  width: 250px;
  margin: 20px auto;
}


button {
  cursor: pointer;

  svg {
    pointer-events: none;
  }
}

.btn {
  font-family: 'univers-lt-std-light-con', sans-serif;
  outline: none;
  border: none;
  padding: 10px 20px 8px 20px;
  background-color: white;
  color: black;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-size: 1.2em;
  box-sizing: border-box;

  svg {
    pointer-events: none;
    height: 0.9em;
    width: 0.9em;
  }
}

.btn--link {
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 0.9em;
  letter-spacing: 2px;
  margin: 0 auto !important;
  padding: 0;
  display: flex;
  justify-self: center;
  align-items: center;
  cursor: pointer;

  svg {
    fill: white;
    width: 1em;
    height: 1em;
    margin-bottom: 4px;
  }
}

.btn--block {
  width: 100%;
}

.btn--lg {
  width: 250px;
  margin-bottom: 10px;
}

.btn--primary {
  background-color: var(--color-primary);
}
.btn--white {
  background-color: white;
  color: var(--color-background);
}

.btn--outline {
  border: 2px solid white;
  color: white;
  background-color: rgba(0,0,0,0.5);

  svg {
    fill: white;
  }
}


.btn--circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.btn-group {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.btn-group .btn {
  margin: 10px auto;

  svg {
    margin-right: 6px;
    margin-bottom: 4px;
  }
}

.btn--with-icon svg{
  margin-right: 6px;
  margin-bottom: 4px;
}

// FORMS
input {
  font-size: 16px;
}
.input-row {
  margin: 10px auto;
  width: 100%;
  box-sizing: border-box;

  input {
    font-size: 16px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
  }
}
.input-row:last-child {
  margin-bottom: 0;
}

.input--outline {
  background: transparent;
  border: 2px solid white;
  outline: none;
  color: white;
}

.input-group {
  display: flex;

  input {
    padding-left: 10px;
    font-size: 16px;
  }

  .btn {
    padding: 10px 8px 5px 8px;
    font-size: 0.8em;
  }
}

.file-upload {
  border: 2px solid #ccc;
  display: inline-block;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  svg {
    fill: white;
    width: 1.5em;
    height: 1.5em;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  input[type="file"] {
    display: none;
  }
}

#wa-auth-instructions {
  display: none;
}

.info-window {
  position: relative;
  max-width: 600px;
  text-align: center;
  padding: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
  box-shadow: 0px 0px 20px #87A2BE;
  // border-radius: 10px;
}
.info-window__content {
  padding: 60px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid white;
  border-radius: 10px;
}


.patchbay-player {
  max-width: 300px;
  border-radius: 6px;
}
#wa-audio-bar {
  max-width: 300px;
  border-radius: 6px;
}
#wa-audiobar__controls {
  width: 100px !important;
  margin-right: 12px;
}
@media (max-width: 700px) { 
  .patchbay-player {
    max-width: 100%;
  }
  #wa-audio-bar {
    max-width: 100%;
    width: 100%;
  }
  #wa-audiobar__controls {
    width: 160px;
  }
}
@media (max-width: 1140px) { 
  .patchbay-player-active #footer{
    bottom: 64px !important;
  }
}

.modal-iframe {
  width: 600px;
}

.iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  background-color: black;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 700px) { 
  .modal-iframe {
    width: 360px;
  }
}