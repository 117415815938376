
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 690px;
  margin: 0 auto;

  p {
    margin-bottom: 8px;
    width: 100%;
  }

  button {
    margin-top: 20px;
    font-size: 1.5em;
  }
}

.loadingHr {
  width: 240px;
}


.loadingCopy {
  font-size: 1.1em;
}
@media (max-width: 800px) {
  .loadingCopy {
    font-size: 1em;
  }
}


@media (max-height: 680px) {
  .loading {
    button {
      font-size: 0.9em;
      padding: 12px;
      margin-top: 6px;
    }
    p {
      font-size: 1em;
    }
  }
}

@media (max-height: 560px) {

  .loading {
    button {
      font-size: 0.8em;
      padding: 10px;
      margin-top: 6px;
    }
    p {
      font-size: 0.9em;
    }
  }
}


.loadingBar {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 2px solid white;
  width: 100%;
  padding: 6px 16px 3px 16px;
  box-sizing: border-box;
  user-select: none;
}

.loadingBar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: white;
  animation: loading 4.5s ease forwards;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}

@media (max-height: 560px) {
  .loadingBar {
    margin-top: 10px;
    padding: 8px;
  }
  .loadingText {
    font-size: 0.8em;
  }
}

.loadingText {
  position: relative;
  color: white;
  mix-blend-mode: difference;
  font-size: 0.8em;
}

.loadingConditional[data-status="loading"] {
  position: relative;
}
.loadingConditional[data-status="enter"] {
  display: none;
}