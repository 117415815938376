.wrapper {
  width: 100%;
}

.header {
  width: 100%;
  text-align: left;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.player {
  padding-right: 20px;
  box-sizing: border-box;
  width: 60%;
}
@media (max-width: 900px) {
  .player {
    width: 100%;
    padding-right: 0;
  }
}

.list {
  width: 40%;
  flex: 0 0 auto;
  height: 300px;
  overflow-y: scroll;
  padding-left: 20px;
  box-sizing: border-box;
  border-left: 1px solid white;

  button {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 900px) {
  .list {
    width: 100%;
    height: 180px;
    padding-left: 0;
    padding-top: 10px;
    margin-top: 10px;
    border-left: none;
    border-top: 1px solid white;
  }
}

.iframeWrap {
  position: relative;
  padding-bottom: 56.25%;
  background-color: black;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}