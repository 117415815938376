.wrapper {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.ballot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.match {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 300px;
}

.match[data-active="false"] {
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease;
}

.match[data-active="true"] {
  opacity: 1;
  pointer-events: all;
  transition: opacity 300ms ease;
}

.songButton{
  border: 2px solid white;
  background: transparent;
  color: white;
  font-size: 1.4em;
  padding: 10px;
  width: 100%;
  font-family: 'univers-lt-std-light-con', sans-serif;
  padding-top: 15px;
}
.songButton[data-won="true"]{
  border: 2px solid white;
  background: white;
  color: black;
}
.match span{
  border-left: 2px solid white;
  border-right: 2px solid white;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 4px;
  user-select: none;
  pointer-events: none;
}

.byeText {
  margin-top: 10px;
}

.progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progressTitle {
  font-size: 1.4em;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.message {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
}
.message h2{
  margin-bottom: 0;
}

.controls {
  margin-top: 10px;
}

.voteForm {
  width: 300px;
}

.voteFormSong {
  margin-bottom: 20px;
  font-size: 1.4em;
}