.controls {
  position: absolute;
  top: 50px;
  left: 0;
  height: 500px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.control {
  position: relative;
  cursor: pointer;
  width: 100%;
  flex: 1 1 auto;

  // input[type='color'] {
  //   width: 100%;
  //   height: 40px;
  // }
}
.control > button {
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;
  border-top: 2px solid white;
  color: white;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;

  svg {
    fill: white;
    width: 30px;
    height: 30px;
    margin-bottom: 8px;
  }
}
.control:first-child > button {
  border-top: 2px solid white;
}

.control > button[data-active="true"] {
  background-color: white;
  color: black;
  
  svg {
    fill: black;
  }
}


@media (max-width: 700px) {
  .controls {
    position: absolute;
    top: 40px;
    left: 0;
    height: 500px;
    max-height: 65vh;
  }
  .control > button {
    font-size: 0.6em;

    svg {
      fill: white;
      width: 20px;
      height: 20px;
    }
  }
}

.flyOut {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: var(--color-background);
  padding: 10px;
  box-sizing: border-box;

  button {
    padding: 4px 2px 2px 2px;
    font-size: 1em;
  }
}

.flyOutTitle {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
}

.option {
  margin-bottom: 10px;
}

.radioGroup {
  display: flex;
  justify-content: space-between;
}

.radioOption {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0px 6px;

  img {
    max-width: 100%;
  }

  input {
    display: none;
  }

  label {
    display: block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background-color: lightgray;
  }
  label::after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 75%;
    height: 75%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  

  input:checked+label {
    border: 1px solid black;
  }
  input:checked+label::before {
    content: attr(data-text);
    position: absolute;
    bottom: -1.5em;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 0.7em;
    text-align: center;
  }
}
.radioOption:first-child {
  margin: 0 6px 0 0;
}
.radioOption:last-child {
  margin: 0 0 0 6px;
}
